<template>
    <ion-page>
        <div>Маршруты</div>
    </ion-page>
</template>

<script>
export default {
    name: 'AdminTracks',
};
</script>

<style scoped>

</style>
